/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';

import Helmet from 'react-helmet';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';

function SEO({
	description,
	lang,
	overwriteMeta = [],
	title,
	titleTemplate,
	schemaJSON,
	image,
	isNoIndex
}) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`
	);

	let metaDescription = description || site.siteMetadata.description;

	let simpleMeta = [
		{
			name: `description`,
			content: Parser(metaDescription)
		},
		{
			name: `og:title`,
			content: Parser(title)
		},
		{
			name: `og:description`,
			content: Parser(metaDescription)
		},
		{
			name: `og:type`,
			content: Parser(`website`)
		},
		{
			name: `twitter:card`,
			content: Parser(`summary`)
		},
		{
			name: `twitter:creator`,
			content: Parser(site.siteMetadata.author)
		},
		{
			name: `twitter:title`,
			content: Parser(title)
		},
		{
			name: `twitter:description`,
			content: Parser(metaDescription)
		}
	];

	let meta = Object.values(
		[...simpleMeta, ...overwriteMeta].reduce((result, { name, ...rest }) => {
			result[name] = {
				...(result[name] || {}),
				name,
				...rest
			};
			return result;
		}, {})
	);

	return (
		<Helmet
			htmlAttributes={{
				lang
			}}
			title={Parser(title)}
			titleTemplate={titleTemplate || `%s | ${site.siteMetadata.title}`}
			meta={meta}
		>
			<meta name="description" content={metaDescription} />
			{schemaJSON && (
				<script type="application/ld+json">{JSON.stringify(schemaJSON)}</script>
			)}

			{image && <meta property="og:image" content={image} />}
			{image && <meta property="twitter:image" content={image} />}

			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
			<link
				href="https://fonts.googleapis.com/css?family=Comfortaa:400,500,700,900|Montserrat:400,500,600,700&display=swap&subset=cyrillic-ext"
				rel="stylesheet"
			/>

			{isNoIndex && <meta name="robots" content="noindex,follow" />}

			{/* anti-flicker snippet (recommended) */}
			<style>{`.async-hide { opacity: 0 !important}`}</style>

			<script>{`(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
      h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
      (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
      })(window,document.documentElement,'async-hide','dataLayer',4000,
      {'GTM-K2KGMLD':true});`}</script>

			<link rel="manifest" href="/manifest.json" />

			<link rel="icon" href="/favicon.png" />
			<link rel="shortcut icon" href="/favicon.png" />
			<script src="https://www.google.com/recaptcha/api.js" async defer></script>
		</Helmet>
	);
}

SEO.defaultProps = {
	lang: `bg`,
	meta: [],
	description: ``,
	isNoIndex: false
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
	schemaJSON: PropTypes.object,
	isNoIndex: PropTypes.bool
};

export default SEO;

