export default {
  socialMediaIcons: [
    {
      link: 'https://www.facebook.com/netsurfpage/',
      icon_class: 'facebook_icon',
    },
    {
      link: 'https://www.instagram.com/net_surf_tv_internet/',
      icon_class: 'instagram',
    },
    {
      link: 'https://www.youtube.com/channel/UCb6E6Xmi_uur1bEFt8dGzGg',
      icon_class: 'youtube',
    },
  ] 
}