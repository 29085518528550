import './header.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Link } from 'gatsby';
import Popup from '../../shared/popup';
import Sticky from 'react-stickynode';
import classnames from 'classnames';
import logo from '../../../images/nav/logo.svg';
import logoWhite from '../../../images/nav/logo-white.svg';
import netsurfplayLogoDark from '../../../images/icons/play-dark.png';
import pages from '../../../constants/pages';
import scrollTo from 'gatsby-plugin-smoothscroll';

const { links: mainNavigationLinks } = pages;

const Header = ({ activeTab }) => {
	const [isNavbarOpen, setNavbarOpen] = useState(false);
	const [showTelephonePopover, setShowTelephonePopover] = useState(false);
	const target = useRef(null);
	const wrapperRef = useRef(null);
	const topNavRef = useRef(null);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});

	const handleClickOutside = (event) => {
		if (
			wrapperRef.current &&
			!wrapperRef.current.contains(event.target) &&
			!topNavRef.current.contains(event.target)
		) {
			setNavbarOpen(false);
		}
	};

	return (
		<header>
			<Sticky enable={true} innerZ={50}>
				<TopNavigation
					isNavbarOpen={isNavbarOpen}
					passRef={topNavRef}
					supportPhone="0885250000"
					signupPhone="0882991611"
				/>
				<MainNavigation
					passRef={wrapperRef}
					isNavbarOpen={isNavbarOpen}
					setNavbarOpen={(v) => setNavbarOpen(v)}
					showTelephonePopover={showTelephonePopover}
					setShowTelephonePopover={(v) => setShowTelephonePopover(v)}
					target={target}
					activeTab={activeTab}
					supportPhone="0885250000"
					signupPhone="0882991611"
				/>
			</Sticky>
		</header>
	);
};

const MainNavigation = ({
	isNavbarOpen,
	setNavbarOpen,
	showTelephonePopover,
	setShowTelephonePopover,
	target,
	activeTab,
	passRef
}) => {
	const isHomePage = activeTab === '/';

	return (
		<nav
			className={
				'navbar navbar-dark navbar-expand-lg d-flex justify-content-between main-navbar px-lg-5 ' +
				(isNavbarOpen && 'open nav-width-mobile')
			}
			ref={passRef}
		>
			<button
				className="navbar-toggler"
				type="button"
				onClick={(_) => {
					setNavbarOpen(!isNavbarOpen);
				}}
			>
				{isNavbarOpen ? (
					<span className="icon navbar-toggler-close-icon"></span>
				) : (
					<span className="navbar-toggler-icon"></span>
				)}
			</button>
			<Logo isNavbarOpen={isNavbarOpen} logo={logo} logoWhite={logoWhite} />
			{!isNavbarOpen ? (
				<div className="d-lg-none d-block">
					<button
						className="navbar-toggler"
						ref={target}
						id="scta-call-mobile"
						onClick={(_) => setShowTelephonePopover(!showTelephonePopover)}
						type="button"
					>
						<span className="icon telephone-toggler-icon"></span>
					</button>
					<Popup
						onHide={() => setShowTelephonePopover(false)}
						showTelephonePopover={showTelephonePopover}
						setShowTelephonePopover={(v) => setShowTelephonePopover(v)}
						target={target}
					>
						<div className="py-4 pl-4 pr-3 popup d-flex justify-content-end align-items-end">
							<div className="text-right">
								<div className="my-2">
									<p className="number-blue">Текущи клиенти:</p>
									<h2 className="number">
										<a href={`tel:0885250000`}>0885250000</a>
									</h2>
								</div>
								<div className="my-2">
									<p className="number-green">Нови клиенти:</p>
									<h2 className="number">
										<a href={`tel:0882991611`}>0882991611</a>
									</h2>
								</div>
							</div>
						</div>
					</Popup>
				</div>
			) : (
				<div></div>
			)}
			<div className="order-2 d-xl-flex justify-content-between d-none">
				{isHomePage ? (
					<button
						className="btn btn-primary green header-button"
						id="pcta-header"
						onClick={() => {
							scrollTo('#package-section', 'start');
						}}
					>
						Виж пакетите
					</button>
				) : (
					<Link
						to={'/za-doma/'}
						className="btn btn-primary green header-button"
						id="pcta-header"
					>
						Виж пакетите
					</Link>
				)}
			</div>
			<div
				className={
					'navbar-collapse collapse w-100 order-2 order-md-0 bottom-radius-sm ' +
					(isNavbarOpen && 'show')
				}
			>
				<NavigationRenderer activeTab={activeTab} isNavbarOpen={isNavbarOpen} />
			</div>
		</nav>
	);
};

const NavigationRenderer = ({ activeTab, isNavbarOpen }) => {
	const [activeSection, setActiveSection] = useState();
	const toggle = useCallback(
		(e) => {
			if (e.currentTarget.classList.contains('no-after')) {
				e.currentTarget.classList.remove('no-after');
				setActiveSection(undefined);
			} else {
				e.currentTarget.classList.add('no-after');
				setActiveSection(e.currentTarget.id);
			}
		},
		[activeSection, setActiveSection]
	);
	const RenderLinkNode = ({ link, isNavbarOpen }) => {
		const { name, href, dropdownItems, showPromoIcon } = link;
		const linkTarget = useRef(null);
		const [showPopup, setShowPopup] = useState(false);

		const hasDropdownItems = dropdownItems && dropdownItems.length > 0;

		if (hasDropdownItems && isNavbarOpen) {
			return (
				<>
					{dropdownItems.map((link, index) => (
						<RenderLinkNode key={index} link={link} />
					))}
				</>
			);
		}
		return (
			<>
				<li
					ref={linkTarget}
					className="mx-3 mt-2 nav-item mt-lg-3 d-flex justify-content-center align-items-center"
				>
					<Link
						to={href}
						className={classnames('nav-link d-inline-block', {
							active: activeTab && activeTab === href,
							promotion: showPromoIcon,
							'is-dropdown': hasDropdownItems,
							'dropdown-open': showPopup
						})}
						onClick={(e) => {
							if (hasDropdownItems) {
								e.preventDefault();
								setShowPopup(!showPopup);
							}
						}}
						onMouseOver={() => setShowPopup(true)}
						onMouseOut={() => setShowPopup(false)}
					>
						<span>{name}</span>
						{hasDropdownItems && (
							<>
								<Popup
									onHide={() => setShowPopup(false)}
									showTelephonePopover={showPopup}
									setShowTelephonePopover={setShowPopup}
									target={linkTarget}
									placement={'bottom'}
								>
									<div className="px-4 py-3 popup d-block">
										{dropdownItems.map(
											({ href, name, externalHref }, index) => (
												<div className="my-2">
													{externalHref && (
														<a
															className="nav-link d-inline-block"
															href="https://blog.netsurf.bg"
														>
															Блог
														</a>
													)}
													{!externalHref && (
														<Link
															key={index}
															to={href}
															className={classnames(
																'nav-link d-inline-block',
																{
																	active:
																		activeTab &&
																		activeTab === href
																}
															)}
														>
															{name}
														</Link>
													)}
												</div>
											)
										)}
									</div>
								</Popup>
							</>
						)}
					</Link>
				</li>
			</>
		);
	};

	return (
		<>
			{isNavbarOpen && (
				<ul className="mx-auto navbar-nav">
					<div className="pt-10 position-relative">
						<h3
							id="service"
							onClick={(e) => toggle(e)}
							className="expand my-3 title-wrapper color-white"
						>
							За Дома
						</h3>
						{activeSection === 'service' && (
							<div className="pl-20">
								<div className="row p-0 m-0">
									<Link
										to="/za-doma/"
										className="d-block w-100 my-2 color-white"
									>
										Срочни
									</Link>
									<Link
										to="/za-doma-bezsrochni/"
										className="d-block w-100 my-2 color-white"
									>
										Безсрочни
									</Link>
								</div>
							</div>
						)}
					</div>

					<div className="pt-10">
						<Link to="/za-biznesa/" className="d-block w-100 color-white">
							<h3 className="my-2 color-white">За бизнеса</h3>
						</Link>
					</div>
					<div className="pt-10">
						<Link to="/svoboda/" className="d-block w-100 color-white">
							<h3 className="my-2 color-white">Свобода</h3>
						</Link>
					</div>
					<div className="pt-10">
						<Link to="/promocii/" className="d-block w-100 color-white ">
							<span className="active promotion"></span>
							<h3 className="my-2 color-white ">Промоции</h3>
						</Link>
					</div>
					<div className="pt-10">
						<Link to="/aurora-tv/" className="d-block w-100 color-white ">
							<h3 className="my-2 color-white">Aurora TV</h3>
						</Link>
					</div>
					<div className="pt-10">
						<Link
							to="/internet-zashtita/"
							className="d-block w-100 color-white "
						>
							<h3 className="my-2 color-white">Закрилникът</h3>
						</Link>
					</div>
					<div className="pt-10 position-relative">
						<h3
							id="help"
							onClick={(e) => toggle(e)}
							className="expand my-3 title-wrapper color-white"
						>
							За Теб
						</h3>
						{activeSection === 'help' && (
							<div className="pl-20">
								<div className="row p-0 m-0">
									<Link
										to="/netsurf-play/"
										className="d-block w-100 my-2 color-white"
									>
										Netsurf Play
									</Link>
									<Link
										to="/kakvo-e-gpon/"
										className="d-block w-100 my-2 color-white"
									>
										Какво е GPON
									</Link>
									<Link
										to="/zashto-netsurf/"
										className="d-block w-100 my-2 color-white"
									>
										Защо NetSurf
									</Link>
									<Link
										to="/help-center/"
										className="d-block w-100 my-2 color-white"
									>
										Помощ
									</Link>
								</div>
							</div>
						)}
					</div>

					<li className="mx-3 my-4 nav-item mt-lg-3 text-center">
						{isNavbarOpen && (
							<>
								<a
									href="https://auth.net-surf.net/realms/ns-customer/protocol/openid-connect/auth?client_id=mobileapp&redirect_uri=https%3A%2F%2Fuserportal.net-surf.net%2F%2Fdashboard&response_mode=fragment&response_type=code&scope=openid"
									className="btn btn-primary green"
									id="pcta-burger"
								>
									Вход
								</a>
							</>
						)}
					</li>
				</ul>
			)}
			{!isNavbarOpen && (
				<ul className="mx-auto navbar-nav">
					{mainNavigationLinks?.map((link, index) => (
						<RenderLinkNode
							key={index}
							link={link}
							activeTab={activeTab}
							isNavbarOpen={isNavbarOpen}
						/>
					))}
					<li className="mx-3 my-4 nav-item mt-lg-3 text-center">
						{isNavbarOpen && (
							<>
								<Link
									to="/za-doma/"
									className="btn btn-primary green"
									id="pcta-burger"
								>
									Виж пакетите
								</Link>
							</>
						)}
					</li>
				</ul>
			)}
		</>
	);
};

const TopNavigation = ({ isNavbarOpen, passRef }) => {
	const target = useRef(null);
	const [show, setShow] = useState(false);

	return (
		<div className={'top-nav ' + (isNavbarOpen && 'open')} ref={passRef}>
			<div className="row mx-lg-5 h-100">
				<div className="col-4 d-none d-lg-flex justify-content-start align-items-center pl-lg-5 h-100"></div>
				<div className="col-lg-3 d-none d-lg-block"></div>
				<div
					className={
						'col-12 col-lg-5 d-flex align-self-center align-items-center justify-content-between justify-content-md-end'
					}
				>
					{!isNavbarOpen && (
						<>
							<div className="topnav-item d-none d-lg-flex justify-content-start align-items-center px-lg-3">
								<div className="mr-3">
									<span className="mr-1 icon phone-icon d-none d-lg-inline-block"></span>
								</div>
								<div className="mr-3">
									<a
										className="contact-number sign-up"
										href={`tel:0882991611`}
									>
										0882991611
									</a>
									<p className="contact-number-undertext">
										нови клиенти
									</p>
								</div>
								<div>
									<a
										className="contact-number current"
										href={`tel:0885250000`}
									>
										0885250000
									</a>
									<p className="contact-number-undertext">
										текущи клиенти
									</p>
								</div>
							</div>
							<div className="px-1  topnav-item d-inline px-md-4 d-lg-none">
								<Link to={'/contact/'}>Контакти</Link>
							</div>
							<div className="topnav-item-divider" />
							<div className="px-1 topnav-item d-inline px-md-4 d-lg-none">
								<a href="https://blog.netsurf.bg">Блог</a>
							</div>
							<div className="topnav-item-divider" />
							<div className="px-1 topnav-item d-inline px-md-4">
								<Link to={'/pokritie/'}>Покритие</Link>
							</div>
							<div className="topnav-item-divider" />
							<div className="px-1 topnav-item d-inline px-md-4">
								<Link to={'/magazini/'}>Магазини</Link>
							</div>
							<div className="topnav-item-divider d-none d-lg-block" />
							<div className="topnav-item d-none d-lg-inline pl-md-4 pr-lg-2">
								<Link
									to={'/contact/'}
									className="btn btn-secondary btn-sm"
								>
									Контакти
								</Link>
							</div>
						</>
					)}

					<Popup
						showTelephonePopover={show}
						setShowTelephonePopover={(v) => setShow(v)}
						target={target}
						onHide={() => setShow(false)}
					>
						<div className="py-4 pl-4 pr-3 popup d-flex justify-content-end align-items-end">
							<div className="text-right justify-content-between">
								<div className="my-2">
									<p className="number-blue">Текущи клиенти:</p>
									<h2 className="number">
										<a href={`tel:0885250000`}>0885250000</a>
									</h2>
								</div>
								<div className="my-2">
									<p className="number-green">Нови клиенти:</p>
									<h2 className="number">
										<a href={`tel:0882991611`}>0882991611</a>
									</h2>
								</div>
							</div>
						</div>
					</Popup>

					<div className="topnav-item d-none d-lg-inline-block pl-lg-2">
						<img
							className="mr-1"
							src={netsurfplayLogoDark}
							width={36}
							height={36}
						/>
						<a
							href="https://auth.net-surf.net/realms/ns-customer/protocol/openid-connect/auth?client_id=mobileapp&redirect_uri=https%3A%2F%2Fuserportal.net-surf.net%2F%2Fdashboard&response_mode=fragment&response_type=code&scope=openid"
							className="ml-lg-0 d-inline-block text-purple text-bold"
						>
							Вход
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

const Logo = ({ isNavbarOpen, logoWhite, logo }) => (
	<Link to={'/'} className={'d-block text-center order-0 order-md-0 '}>
		<img src={isNavbarOpen ? logoWhite : logo} className="logo" alt="Logo" />
	</Link>
);

export default Header;

