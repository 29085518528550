import { CookiesProvider } from 'react-cookie';
import Footer from './footer';
import Header from './base/header/header';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

const Layout = ({ activeTab, landing, children, hideFooter = false }) => {
	return (
		<CookiesProvider>
			<div className="p-0 m-0 col-12">
				<div style={{ position: 'relative' }}>
					<div style={{ position: 'absolute', zIndex: 1000, width: '100%' }}>
						<Header activeTab={activeTab} landing={landing} />
					</div>
					<main
						className={cx({
							'bg-black': activeTab === '/aurora-tv/'
						})}
					>
						{children}
					</main>
				</div>
				<MessengerCustomerChat
					pageId="117995308241723"
					appId="436501507040779"
					themeColor="#6d57c6"
					loggedInGreeting="Привет! С какво да съдействаме?"
					loggedOutGreeting="Привет! С какво да съдействаме?"
				/>
				{!hideFooter && <Footer />}
			</div>
		</CookiesProvider>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;

