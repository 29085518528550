import { Overlay } from 'react-bootstrap';
import React from 'react';

const Popup = ({
	showTelephonePopover,
	target,
	children,
	placement = 'auto',
	onHide
}) => {
	return (
		<Overlay
			target={target.current}
			show={showTelephonePopover}
			placement={placement}
			flip={true}
			rootClose={true}
			rootCloseEvent={'click'}
			onHide={onHide}
		>
			{({
				placement,
				scheduleUpdate,
				arrowProps,
				outOfBoundaries,
				rootClose,
				rootCloseEvent,
				onHide,
				show: _show,
				...props
			}) => <div {...props}>{children}</div>}
		</Overlay>
	);
};

export default Popup;

